import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@mui/material';
import React from 'react'
import { useStylesNewChat } from '../css/mainCss';


const NewChatPopUp = ( { open, clickNo, clickYes } ) =>
{
 let classes= useStylesNewChat();
  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle className={classes.customTextAlign}>Your current chat will be cleared.</DialogTitle>
      <DialogContent className={classes.mainContent}>
         Are you sure you want to start a new chat?
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant='outlined' className={classes.noButton} onClick={clickNo}>No</Button>
        <Button variant='contained' className={classes.yesButton } onClick={clickYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewChatPopUp