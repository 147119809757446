import axios from 'axios';
import { baseUrl, accessToken } from '../config/config';
import { toast } from 'react-toastify';

export const sendEmailToCandidate = async ( payload ) =>
{
	try {
		const headers = {
			'Content-Type': 'application/json',
			access_token: accessToken,
		};

		const response = await axios.post(`${baseUrl}api/v1/email`, payload, {
			headers: headers,
		});
		if (response.status === 200) {
			toast.dark(response.data.message);
		}
	} catch (error) {
		console.error('Error:', error);
	}
};

export const sendCandidateInfo = async (payload) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			access_token: accessToken,
		};
		const response = await axios.post(`${baseUrl}api/v1/user_info`, payload, {
			headers: headers,
		});
		if (response.status === 200) {
			toast.dark(response.data.message);
		}
	} catch (error) {
		console.error( 'Error:', error );
		return error;
	}
};


export const callSession = async () => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			 access_token: accessToken,
		};
		const response = await axios.get(`${baseUrl}api/v1/session`, {
			headers: headers,
		} );
		if (response.status === 200) {
			toast.dark(response.data.message);
			
			// store session id in local storage
			localStorage.setItem('sessionId', response.data.session_id);
		}
	} catch (error) {
		console.error( 'Error:', error );
		return error;
	}
};


export const chatWithBot = async (inputText, type) => {
	try {
		const history = localStorage.getItem('responseMessages') || '[]';
		let parsedHistory;
		try {
			parsedHistory = JSON.parse(history);
		} catch (error) {
			console.error('Error parsing history:', error);
			parsedHistory = [];
		}

		let userDetails = localStorage.getItem('userDetails') || '{}';
		let parsedUserDetails;
		try {
			parsedUserDetails = JSON.parse(userDetails);
		} catch (error) {
			console.error('Error parsing user details:', error);
			parsedUserDetails = { name: '', email: ''};
		}
		
		//get name and email from local storage
		const { name, email } = parsedUserDetails;

		const requestBody = {
			history: parsedHistory,
			input_text: inputText,
			session_id: localStorage.getItem('sessionId') || '',
			name : name,
			email : email,
		};
		const headers = {
			'Content-Type': 'application/json',
			access_token: accessToken,
		};
		const response = await axios.post(
			type === 'Generator'
				? `${baseUrl}api/v1/jd_generation/response`
				: `${baseUrl}api/v1/jd_fixing/response`,
			requestBody,
			{ headers: headers }
		);
		const messages = response.data.data;
		return messages;
	} catch (error) {
		console.error('Error:', error);
	}
};
