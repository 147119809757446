import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputLabel,
	TextField,
} from '@mui/material';
import { useStylesUserDeatail } from '../css/mainCss';
import { sendCandidateInfo, callSession } from '../api/api';
import { toast } from 'react-toastify';

const GetUserInfo = ({ open, setOpen }) => {
	const classes = useStylesUserDeatail();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');

	const [validate, setValidate] = useState({
		name: false,
		email: false,
		company: false,
	});

	const onChangeName = (e) => {
		setName(e.target.value);
		if (e.target.value.trim() === '') {
			setValidate({ ...validate, name: true });
		} else {
			setValidate({ ...validate, name: false });
		}
	};

	const onChangeCompany = (e) => {
		setCompany(e.target.value);
		if (e.target.value.trim() === '') {
			setValidate({ ...validate, company: true });
		} else {
			setValidate({ ...validate, company: false });
		}
	};

	const onChangeEmail = (e) => {
		setEmail(e.target.value);
		let isValid = validateEmail(e.target.value);
		setValidate({ ...validate, email: !isValid });
	};

	const validateEmail = (email) => {
		const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
		return emailPattern.test(email);
	};

	const submit = async () => {
		let error = {};
		if (name.trim() === '') {
			error.name = true;
		} else {
			error.name = false;
		}

		if (company.trim() === '') {
			error.company = true;
		} else {
			error.company = false;
		}

		let isValid = validateEmail(email);
		error.email = !isValid;

		setValidate(error);

		if (!error.email && !error.name) {
			let payload = {
				name: name,
				email: email,
				company:company
			};
			let res = await sendCandidateInfo(payload);
			if ( res && res.response.status === 400 )
			{
				debugger
				toast.error(res.response?.data?.detail);
			} else {
				localStorage.setItem(
					'userDetails',
					JSON.stringify( { name: name, email: email, company:company})
				);
				await callSession();
				setOpen(false);
			}
		}
	};

	return (
		<div>
			<Dialog open={open} fullWidth maxWidth='lg'>
				<DialogTitle
					align='center'
					fontSize={35}
					color={'#000'}
					style={{ padding: '5px 20px' }}
					fontWeight={600}>
					Let's "Taylor" Your Experience
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						fontSize={20}
						align='center'
						color={'#000'}
						>
						Share a few details with us, and we'll ensure our AI-driven job description generator aligns with your unique needs. Your information is in safe hands and primarily used to send job descriptions directly to your email. We're committed to maintaining your trust.
					</DialogContentText>
					<div className={classes.inputDiv}>
						<InputLabel
							error={validate.name}
							className={classes.inputLable}
							required>
							Name
						</InputLabel>
						<TextField
							type='text'
							fullWidth
							value={name}
							onChange={onChangeName}
							error={validate.name}
							helperText={validate.name && 'This field is required!'}
						/>
					</div>
					<div className={classes.inputDiv}>
						<InputLabel
							error={validate.email}
							className={classes.inputLable}
							required>
							Email
						</InputLabel>
						<TextField
							type='email'
							fullWidth
							value={email}
							onChange={onChangeEmail}
							error={validate.email}
							helperText={validate.email && 'Please enter valid email'}
						/>
					</div>
					<div className={classes.inputDiv}>
						<InputLabel
							error={validate.company}
							className={classes.inputLable}
							required>
							Company Name
						</InputLabel>
						<TextField
							type='text'
							fullWidth
							value={company}
							onChange={onChangeCompany}
							error={validate.company}
							helperText={validate.company && 'This field is required!'}
						/>
					</div>
					<div className={classes.inputDiv}>
						<Button
							onClick={submit}
							className={classes.submitButton}
							variant='contained'>
							SUBMIT
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default GetUserInfo;
