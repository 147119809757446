export const BUTTONS = {
  START_CREATEING_JD: "Start Creating Job Description",
  NEW_CHAT: "New Chat",
  OPTIMIZE_EXISTING_JOB_DESCRIPTION: "Optimize existing job description",
  CREATE_NEW_JOB_DESCRIPTION: "Create new job description",
};

export const DAILOG_BOX_HEADING = {
  MAIN: {
    HEADING: "Welcome to SucceedSmart's JobDescriber",
    SUBHEADING: "Tell us a bit about yourself...",
    CONTENT:
      "The smart tool that automates job description creation, saving time and ensuring accuracy.With advanced algorithms and natural language processing, JobDescriber crafts tailored and compelling job descriptions, maximizing candidate attraction and streamlining the hiring process.",
  },
};

export const COMPONENT_CONSTANT_TEXT = {
  JOB_JPT: {
    HEADING: "Example",
    BOT_IS_TYPING: "Taylor is Typing",
    EXAMPLE_CONTENT:
      "Create a job description with title Director of Software Engineering with responsibilities Developing and maintaining software applications, troubleshooting and debugging code, collaborating with cross-functional teams on software development projects.",
    TYPE_HERE: "Type your prompt",
  },
};

export let DEFAULT_MESSAGES = {
  MESSAGE_FOR_NAME: "Hi I'm Taylor. What's your name?",
  MESSAGE_FOR_EMAIL:
    "What is your email? We will send you a copy of the job description to your email. When you're happy with the generated job description, you can just click on the email icon at the top!",
  MESSAGE_FOR_OPTIONS: `I'm here to help you create an unbiased job description. To kick things off, please click on one of the two options below:`,
  ON_SELECTION_MESSAGE_GENERATOR:
    "Great! Could you jot down the job title and some key details in the text area below? Remember, you'll get the chance to tweak and tailor it just how you like. Thank you!",
  ON_SELECTION_MESSAGE_FIXER:
    "Awesome! Could you do me a favor and copy-paste your current job description into the text area below? This way, you'll be able to give it a quick review, add a personal touch, and finalize quickly. Thank you!",
};
