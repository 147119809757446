import { makeStyles } from '@material-ui/core/styles';


export const useStylesNewChat = makeStyles((theme) => ({
	customTextAlign: {
	textAlign:'center'
	},
	mainContent: {
		fontFamily: 'sans-serif',textAlign:'center'
	},
	actions: {
		display:'flex !important',justifyContent:'center !important',marginTop:'10px !important'
	},
	noButton: {
		border:'1px solid #FBC112 !important',color:'#FBC112 !important',padding:'6px 40px !important'
	},
	yesButton: {
		background:'#FBC112 !important',color:'#FFF !important',padding:'6px 40px !important'
	}
}));

export const useStylesUserDeatail = makeStyles((theme) => ({
	inputLable: {
		color: '#000 !important',
		fontWeight: '600 !important',
	},
	inputDiv: {
		padding: '20px 30px !important',
	},
	submitButton: {
		width: '100%',
		background: '#FBC112 !important',
		color: '#000 !important',
		fontWeight: '600 !important',
		height:"3rem !important"
	},
}));

export const useStylesJobJpt = makeStyles((theme) => ({
	root: {
		height: '100vh !important',
	},
	headingDiv: {
		height: '5vh',
		fontSize: '25px',
		fontFamily: 'sans-serif',
		display: 'contents !important',
	},
	scrollDownButton: {
		position: 'absolute !important',
		bottom: '90px !important',
		right: '50px !important',
		zIndex: '99 !important',
		fontSize: '18px !important',
		background: '#303030 !important',
	},
	mainGrid: {
		height: '90vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	paper: {
		width: '80%',
		height: '90%',
		borderRadius: '90px',
		position: 'relative',
		padding: 0,
		overflow: 'hidden',
	},

	contentAi: {
		fontSize: '16px',
		fontFamily: 'sans-serif',
		fontWeight: 400,
		lineHeight: '24px',
		letterSpacing: '0.1px',
		textAlign: 'justify',
		alignItems: 'center',
		overflowWrap: 'normal',
		lineBreak: 'normal',
		display: 'flex',
		padding: '20px 20px',
		color: '#000 !important',
		gap: '20px',
		background: '#f9f8f8',
		justifyContent: 'start',
		flexDirection: 'initial',
		overflow: 'hidden',
	},
	chatHuman: {
		fontSize: '16px',
		fontFamily: 'sans-serif',
		fontWeight: 400,
		lineHeight: '24px',
		letterSpacing: '0.1px',
		textAlign: 'justify',
		alignItems: 'center',
		overflowWrap: 'normal',
		lineBreak: 'normal',
		display: 'flex',
		padding: '20px 20px',
		color: '#000 !important',
		gap: '10px',
		background: '#fff',
		justifyContent: 'end',
		flexDirection: 'row-reverse',
		overflow: 'hidden',
		wordBreak: 'break-all',
	},
	contentMainGrid: {
		background: '#F4F4F5',
		color: '#000',
		borderRadius: '10px',
		padding: '10px 20px',
	},
	parentGrid: {
		display: 'flex',
		justifyContent: 'center',
	},
	logoDiv: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '20px',
	},
	heading: {
		fontSize: '28px',
		fontFamily: 'SF Pro Display',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '24px',
		letterSpacing: '0.028px',
		color: '#14131F',
		textAlign: 'center',
	},
	textFieldStyle: {
		width: '100%',
		background: 'white',
	},
	textFieldDiv: {
		position: 'absolute',
		bottom: 10,
		left: 28,
		right: 0,
		width: '96%',
		[theme.breakpoints.down('md')]: {
			left: 9,
		},
	},
	avatarLogo: {
		background: 'green !important',
		height: '50px !important',
		width: '50px !important',
		textTransform: 'capitalize',
		fontWeight: 600,
	},
	newChatButton: {
		border: '2px solid white !important',
		color: '#FFF !important',
		fontWeight: '600 !important',
	},
	newChatButtonGrid: {
		display: 'flex',
		justifyContent: 'space-between !important',
		alignItems: 'center',
		background: '#303030',
		padding: '20px 15px !important',
	},
	botTypeDivStyle: {
		display: 'flex !important',
		alignItems: 'center !important',
	},
	botTypeingText: {
		marginLeft: '15px',
		fontSize: '12px',
	},
	sendEmail: {
		background: '#FBC112 !important',
		color: '#000 !important',
	},
	chatTypeButtonSpan: {
		display: 'flex !important',
		justifyContent: 'end !important',
	},
	typeButton: {
		marginRight: '5px !important',
		background: '#FBC112 !important',
	},
	typeButton1: {
		marginRight: '10px !important',
		color: '#FBC112 !important',
		border: '1px solid #FBC112 !important',
	},
}));
