import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import {
	Avatar,
	Grid,
	Paper,
	TextField,
	InputAdornment,
	Button,
	IconButton,
	InputLabel,
	Tooltip,
	Chip,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as Logo } from './assets/Logo.svg';
import { ReactComponent as Send } from './assets/Send.svg';
import { ReactComponent as Beta } from './assets/Beta.svg';

import Loaders from './assets/loading.gif';
import Typing from './assets/square-loader.gif';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import EmailIcon from '@mui/icons-material/Email';
import { useStylesJobJpt } from './css/mainCss';
import { BUTTONS, COMPONENT_CONSTANT_TEXT, DEFAULT_MESSAGES } from './Constant';
import {
	callSession,
	chatWithBot,
	sendCandidateInfo,
	sendEmailToCandidate,
} from './api/api';
import { marked } from 'marked';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import GetUserInfo from './core/getUserInfo';
import SendEmail from './core/sendEmail';
import NewChatPopUp from './core/newChatPopUp';

let messagesMy;
let inputValue;
function JobJPT() {
	const classes = useStylesJobJpt();
	const [inputText, setInputText] = useState('');
	const [responseMessages, setResponseMessages] = useState([]);
	const [loder, setLoder] = useState(false);
	const [latestMessage, setLatestMessage] = useState(true);
	const [showButton, setShowButton] = useState(true);
	const [basicMessage, setBasicMessage] = useState([]);
	const [email, setEmail] = useState('');
	let containerRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState({
		x: 0,
		y: 0,
	});
	const [selectedMessageForEmail, setSelectedMessageForEmail] = useState('');
	const [contextMenuOpen, setContextMenuOpen] = useState(false);
	const [isOpenNewChat, setIsOpenNewChat] = useState(false);
	useEffect(() => {
		getExistingMessages();
		getExistingBasicMessage();
		// eslint-disable-next-line
		JSON.parse(localStorage.getItem('userDetails'))?.name
			? setOpen(false)
			: setOpen(true);
	}, []);

	const getExistingBasicMessage = () => {
		const basicStoredMessage = JSON.parse(
			localStorage.getItem('basicMessage')
		) || [DEFAULT_MESSAGES.MESSAGE_FOR_OPTIONS];
		if (basicStoredMessage) {
			setBasicMessage(basicStoredMessage);
			localStorage.setItem('basicMessage', JSON.stringify(basicStoredMessage));
		}
	};

	const getExistingMessages = () => {
		const storedMessages = localStorage.getItem('responseMessages');
		if (storedMessages) {
			setResponseMessages(JSON.parse(storedMessages));
		}
	};

	const saveMessagesToLocalStorage = (messages) => {
		localStorage.setItem('responseMessages', JSON.stringify(messages));
	};

	const handleInputChange = (event) => {
		setInputText(event.target.value);
	};

	const sendMessage = async (e) => {
		e.preventDefault();
		inputValue = inputText.slice();
		setInputText('');
		messagesMy = JSON.parse(localStorage.getItem('basicMessage')) || [];
		messagesMy[0] = DEFAULT_MESSAGES.MESSAGE_FOR_OPTIONS;
		if (inputText) {
			setLoder(true);
			localStorage.setItem('basicMessage', JSON.stringify(messagesMy));
			setBasicMessage(messagesMy);
			if (messagesMy[1] === DEFAULT_MESSAGES.ON_SELECTION_MESSAGE_GENERATOR) {
				let messages = await chatWithBot(inputValue, 'Generator');
				setResponseMessages(messages);
				saveMessagesToLocalStorage(messages);
			}
			if (messagesMy[1] === DEFAULT_MESSAGES.ON_SELECTION_MESSAGE_FIXER) {
				let messages = await chatWithBot(inputValue, 'fixer');
				setResponseMessages(messages);
				saveMessagesToLocalStorage(messages);
			}
			setLoder(false);
		}
	};

	const onsubmitEmail = async () => {
		messagesMy = JSON.parse(localStorage.getItem('basicMessage')) || [];
		let isValid = validateEmail(
			email ? email : JSON.parse(localStorage.getItem('userDetails'))?.email
		);
		if (isValid) {
			let payload = {
				name: JSON.parse(localStorage.getItem('userDetails'))?.name,
				company: JSON.parse(localStorage.getItem('userDetails'))?.company,
				email: email
					? email
					: JSON.parse(localStorage.getItem('userDetails'))?.email,
			};
			let res = await sendCandidateInfo(payload);
			if (res && res.response.status === 400) {
				toast.error('Please enter a valid email');
			} else {
				sendEmail();
				localStorage.setItem(
					'userDetails',
					JSON.stringify({
						name: JSON.parse(localStorage.getItem('userDetails')).name,
						email: email
							? email
							: JSON.parse(localStorage.getItem('userDetails')).email,
						company: JSON.parse(localStorage.getItem('userDetails')).company,
					})
				);
			}
		} else {
			toast.error('Please enter valid email');
		}
	};

	const clearChat = () => {
		setIsOpenNewChat(true);
	};

	const clearChatNoHandle = () => {
		setIsOpenNewChat(false);
	};

	const clearChatYesHandle = () =>
	{
		callSession();
		setShowButton(true);
		localStorage.removeItem('responseMessages');
		setResponseMessages([]);
		let defaultMessage = [DEFAULT_MESSAGES.MESSAGE_FOR_OPTIONS];
		setBasicMessage(defaultMessage);
		localStorage.setItem( 'basicMessage', JSON.stringify( defaultMessage ) );
		clearChatNoHandle()
	}

	const validateEmail = (email) => {
		const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
		return emailPattern.test(email);
	};

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	}, [responseMessages, latestMessage, basicMessage]);

	const onHandleScroll = () => {
		if (
			containerRef.current?.scrollHeight -
				Math.abs(containerRef.current?.scrollTop) ===
				containerRef.current?.clientHeight ||
			containerRef.current?.scrollHeight -
				Math.abs(containerRef.current?.scrollTop) ===
				containerRef.current?.clientHeight - 1
		) {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
	};

	const sendEmail = () => {
		let latestmessageForEmail = '';
		responseMessages.forEach((value) => {
			if (value.type === 'ai') {
				latestmessageForEmail = value.data.content;
			}
		});

		const requestBody = {
			to_email: JSON.parse(localStorage.getItem('userDetails'))?.email,
			content: marked(latestmessageForEmail),
			name: JSON.parse(localStorage.getItem('userDetails'))?.name,
		};

		if (latestmessageForEmail) {
			sendEmailToCandidate(requestBody);
		}
	};

	const openEmailPopup = () => {
		setOpenSendEmail(!openSendEmail);
	};

	const handleCloseEmail = () => {
		setOpenSendEmail(false);
	};

	const handleContentMenu = (event) => {
		event.preventDefault();
	};

	const handleJobDescriptionType = (value) => {
		messagesMy = JSON.parse(localStorage.getItem('basicMessage'));
		if (value === BUTTONS.OPTIMIZE_EXISTING_JOB_DESCRIPTION) {
			messagesMy[1] = DEFAULT_MESSAGES.ON_SELECTION_MESSAGE_FIXER;
		} else {
			messagesMy[1] = DEFAULT_MESSAGES.ON_SELECTION_MESSAGE_GENERATOR;
		}
		setBasicMessage(messagesMy);
		localStorage.setItem('basicMessage', JSON.stringify(messagesMy));
	};

	const onChangeEditEmail = (event) => {
		setEmail(event.target.value);
	};

	const handleContextMenuOpen = (event, message) => {
		event.preventDefault();
		setContextMenuPosition({ x: event.clientX, y: event.clientY });
		setSelectedMessageForEmail(message.data.content);
		setContextMenuOpen(true);
	};

	const handleContextMenuClose = () => {
		setContextMenuOpen(false);
	};

	const handleSendAsEmail = () => {
		sendEmailWithMessage(selectedMessageForEmail);
		handleContextMenuClose();
	};

	const sendEmailWithMessage = (message) => {
		// Logic to send the selected message as an email

		const requestBody = {
			to_email: JSON.parse(localStorage.getItem('userDetails'))?.email,
			content: marked(message),
			name: JSON.parse(localStorage.getItem('userDetails'))?.name,
		};

		if (message) {
			sendEmailToCandidate(requestBody);
		}
	};

	const handleCopyMessage = (message) => {
		const textToCopy = message.data.content;
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				// Optionally, you can show a notification or toast indicating that the message has been copied.
				console.log('Message copied:', textToCopy);
			})
			.catch((error) => {
				console.error('Failed to copy message:', error);
			});
	};
	return (
		<>
			<div
				className={classes.root}
				onCopy={handleContentMenu}
				onContextMenu={handleContentMenu}>
				<div className={classes.headingDiv}>
					<p style={{ textAlign: 'center' }}>Meet Taylor</p>
					<p style={{ textAlign: 'center', fontWeight: 600 }}>
						Fast, Unbiased Job Descriptions Made Effortlessly
					</p>
				</div>
				<Grid container>
					<Grid
						item
						sm={12}
						xs={12}
						md={12}
						lg={12}
						xl={12}
						className={classes.mainGrid}>
						<Paper
							sx={{
								borderRadius: '20px',
								border: '0.10px solid gray',
							}}
							elevation={3}
							className={classes.paper}>
							<div className={classes.newChatButtonGrid}>
								<span>
									<Beta />
								</span>
								<span>
									{responseMessages?.length > 0 && (
										<Tooltip title='Send latest message as email' arrow>
											<IconButton onClick={openEmailPopup}>
												<EmailIcon
													style={{ color: '#FFF', paddingRight: '1rem' }}
												/>
											</IconButton>
										</Tooltip>
									)}
									<Button
										className={classes.newChatButton}
										onClick={clearChat}
										startIcon={<AddIcon />}>
										New Chat
									</Button>
								</span>
							</div>
							<div
								ref={containerRef}
								onScroll={onHandleScroll}
								className='chat-messages'>
								{basicMessage?.map((value, index) => {
									return (
										<>
											<div
												className={classes.contentAi}
												key={index}
												style={{
													background:
														index % 2 === 0 || index === 1 ? '' : '#e7e4e4',
												}}>
												<span>
													{index % 2 === 0 || index === 1 ? (
														<Logo />
													) : (
														<Avatar
															variant={'circular'}
															src='/'
															className={classes.avatarLogo}
															alt={
																JSON.parse(localStorage.getItem('userDetails'))
																	?.name
															}
														/>
													)}
												</span>
												<span>
													<span>
														{index === 0
															? `Hi ${
																	JSON.parse(
																		localStorage.getItem('userDetails')
																	)?.name.split(' ')[0]
															  }. ${value}`
															: '' + value}
													</span>
												</span>
											</div>
										</>
									);
								})}
								{responseMessages.map((message, index) => (
									<div
										className={classes.contentAi}
										onContextMenu={(e) => handleContextMenuOpen(e, message)}
										style={{ background: index % 2 !== 0 ? '' : '#e7e4e4' }}
										key={index}>
										<span>
											{message.type === 'ai' ? (
												<Logo />
											) : (
												<Avatar
													variant={'circular'}
													src='/'
													className={classes.avatarLogo}
													alt={
														JSON.parse(localStorage.getItem('userDetails'))
															?.name
													}
												/>
											)}
										</span>
										<span>
											<ReactMarkdown>{message.data.content}</ReactMarkdown>
										</span>{' '}
										<Menu
											keepMounted
											open={contextMenuOpen}
											onClose={handleContextMenuClose}
											anchorReference='anchorPosition'
											anchorPosition={
												contextMenuPosition.y !== null &&
												contextMenuPosition.x !== null
													? {
															top: contextMenuPosition.y,
															left: contextMenuPosition.x,
													  }
													: undefined
											}>
											{message.type === 'ai' ? (
												<MenuItem onClick={() => handleSendAsEmail(message)}>
													Send as email
												</MenuItem>
											) : (
												<MenuItem onClick={() => handleCopyMessage(message)}>
													Copy
												</MenuItem>
											)}
										</Menu>
									</div>
								))}
								{loder && (
									<div
										className={classes.contentAi}
										style={{ background: '#e7e4e4' }}>
										<span>
											<Avatar
												variant={'circular'}
												src='/'
												className={classes.avatarLogo}
												alt={
													JSON.parse(localStorage.getItem('userDetails'))?.name
												}
											/>
										</span>
									<span><ReactMarkdown>{inputValue}</ReactMarkdown></span>	
									</div>
								)}
								{!showButton && (
									<div>
										<IconButton
											size='lg'
											onClick={() => setLatestMessage(!latestMessage)}
											className={classes.scrollDownButton}>
											<ExpandCircleDownIcon
												style={{ color: '#FFF', fontSize: '30px' }}
											/>
										</IconButton>
									</div>
								)}
							</div>
							<div className={classes.textFieldDiv}>
								<InputLabel>
									{loder && (
										<div className={classes.botTypeDivStyle}>
											<span className={classes.botTypeingText}>
												{COMPONENT_CONSTANT_TEXT.JOB_JPT.BOT_IS_TYPING}
											</span>
											<img
												alt={Typing}
												height={'30px'}
												width={'30px'}
												src={Typing}></img>
										</div>
									)}
								</InputLabel>
								<TextField
									multiline
									maxRows={3}
									id='outlined-multiline-flexible'
									onKeyDown={(e) => (e.key === 'Enter' ? sendMessage(e) : '')}
									value={inputText}
									onChange={handleInputChange}
									placeholder={
										basicMessage?.length !== 1 &&
										COMPONENT_CONSTANT_TEXT.JOB_JPT.TYPE_HERE
									}
									InputProps={{
										readOnly: basicMessage?.length === 1,
										sx: { borderRadius: 5 },
										startAdornment: (
											<>
												{basicMessage?.length === 1 && (
													<>
														<Chip
															label='Use Existing Job Description'
															style={{ cursor: 'pointer' }}
															sx={{ backgroundColor: '#FBC112' }}
															onClick={() =>
																handleJobDescriptionType(
																	BUTTONS.OPTIMIZE_EXISTING_JOB_DESCRIPTION
																)
															}></Chip>
														<Chip
															onClick={() =>
																handleJobDescriptionType(
																	BUTTONS.CREATE_NEW_JOB_DESCRIPTION
																)
															}
															style={{ cursor: 'pointer', marginLeft: '5px' }}
															sx={{ backgroundColor: '#FBC112' }}
															label='Create New Job Description'></Chip>
													</>
												)}
											</>
										),
										endAdornment: (
											<InputAdornment
												style={{ cursor: 'pointer' }}
												position='end'>
												<IconButton
													disabled={basicMessage?.length === 5}
													onClick={sendMessage}>
													{!loder ? (
														<Send />
													) : (
														<IconButton type='submit'>
															<img
																alt={Loaders}
																height={'30px'}
																width={'30px'}
																src={Loaders}></img>
														</IconButton>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									className={classes.textFieldStyle}
								/>
							</div>
						</Paper>
					</Grid>
				</Grid>
				{<GetUserInfo open={open} setOpen={setOpen} />}
				<NewChatPopUp open={isOpenNewChat} clickYes={clearChatYesHandle} clickNo={clearChatNoHandle} />
				<SendEmail
					open={openSendEmail}
					emailValue={JSON.parse(localStorage.getItem('userDetails'))?.email}
					onChangeEditEmail={onChangeEditEmail}
					onsubmitEmail={onsubmitEmail}
					onClose={handleCloseEmail}
				/>
				<p style={{ textAlign: 'center', padding: '2rem' }}>
					<u>Disclaimer:</u> Taylor is powered by AI to deliver unbiased job
					descriptions. However, inaccuracies may arise. Always cross-check
					information and{' '}
					<a href='mailto:taylor@succeedsmart.com'>offer feedback</a> to help
					us enhance accuracy.
				</p>
			</div>
		</>
	);
}
export default JobJPT;
